/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
/**
 * Table Of Content
 *
 * 	1. Globals
 *	2. Headers
 *	3. Navigations
 *	4. Banners
 *	5. Footers
 *	6. app
 *	7. Widgets
 *	8. Custom Templates
 */

@import 'variable';

@import 'app';
@import 'header';
@import 'pages';
@import 'sidebar';
@import 'responsive';

@import "../styles/icons/material-design-iconic-font/css/materialdesignicons.min.css";

.blueheader {
    /*padding: 25px;*/
    color: #0079C1;
    margin: 0;
    @apply tw-text-2xl;
}

.mat-card-header-text {
  margin: 0 8px !important;
}

.whatsappmessengermain
  {
    position: absolute;
    top: 20px;
    right: 0px;
    color: black;
    border-radius: 10px 0px 0px 10px;
    background: #4caf50;
    padding: 5px;
    padding-left: 10px;
    padding-right:10px;
    display: flex;
    align-items: center;
    font-size:0.8em;
    z-index:9999;
  }

  .tncmain
  {
    position: absolute;
    top: 30px;
    right: 0px;
    color: black;
    border-radius: 10px 0px 0px 10px;
    background: #4caf50;
    padding: 0px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    font-size: 0.7em;
    z-index: 9999;
  }

.bluebar
{
    background-color: #2c3e50;
    color:white;
}

.expansion-padding {
  @apply tw-mx-1 #{!important};
}

.headerfont {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  display: flex;
  align-items: flex-start;

  color: #212529;
}

.mat-card-layout {
  box-shadow: 0px 2px 10px rgba(126, 126, 126, 0.25) !important;
  border-radius: 8px !important;
}

.mat-header-layout {

  /*padding-top:.5rem !important;
  padding-bottom: .5rem !important;*/
  @apply tw-py-2 #{!important};
  background: #127BBF;
  border-radius: 8px 8px 0px 0px !important;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;

  color: #FFFFFF;
}

.form-control {
  border: 1px solid #CFCFCF !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
}

.btn-save {
  background: #127BBF;
  border-radius: 6px;
  padding-left:40px;
  padding-right:40px;
  font-size: 1rem !important;
  text-transform: uppercase;
}


.chart-control {
  border: 1px solid #CFCFCF;
  box-sizing: border-box;
  border-radius: 4px;
}
