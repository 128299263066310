@use '~ag-grid-community/styles' as ag;

/*@import 'tailwindcss/base';*/
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import '~ngx-sharebuttons/themes/default';

html, body {
    min-height:100%;
    font-family: 'Poppins', sans-serif !important;
}

body {
    height: 100vh;
}

.disabled {
    pointer-events: none;
    cursor: not-allowed;
}

.responsive-table {
    overflow-x: auto;
    overflow-y: hidden;
}

.flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 8px;
    flex-wrap: wrap-reverse;
    width: 100%;
}

.flex-start {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 8px;
    flex-wrap: wrap-reverse;
    width: 100%;
}

.white-background {
    background-color: white;
}

.ck-editor__editable_inline {
    height: 100% !important;
    min-height: 150px !important;
}

/* Styles for centering table content */
.mat-sort-header-container {
    justify-content: center;
}

/*.mat-cell {
    text-align: center !important;
    justify-content: center !important;
}*/

/*.mat-chip-list-wrapper {
    justify-content: center;
}*/

.mat-row:nth-child(even){
    background-color: transparent;
}

.mat-row:nth-child(odd){
    background-color:rgb(223, 245, 252);
}

.mat-select-value{
    overflow:visible !important;
}

@include ag.grid-styles(
    (
        themes: (
            alpine: (
                range-selection-border-color: null,
            ),
            custom: (
                extend-theme: alpine,

                border-color: #dfdfdf,
                borders: solid 0px,
                borders-critical: solid 1px,
                borders-row: solid 1px,
                header-background-color: null,
                range-selection-border-color: null,
            ),
        ),
    )
);
