

* {
    outline: none;
    box-sizing:border-box;
}

.back-btn {
    border: 0px ;
    font-size:0.7em;
    cursor:pointer;
    background-color:transparent;
    font-weight: bolder;
    font-size: medium;
    color: #C6006F;
}

.back-button
{
    border-radius: 15px !important;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 800;
    margin-top: 2vh;
    margin-bottom: 2vh;
}

@media only screen and (max-width: 800px) {

  .mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
    font-size:10px !important;
  }

  .mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
    font-size:10px !important;
  }

  .customdialog {
    width:100% !important;
    max-width:90vw !important;
  }
}

.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
    border-radius: 12px;
    margin-top: 2vh;
    margin-bottom: 2vh;
    color: #C6006F;
    border-color: #C6006F;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 800;
    font-size:12px;
    padding-top: 1px;
}

.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
    background-color: #C6006F;
    border-radius: 12px;
    margin-top: 2vh;
    margin-bottom: 2vh;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 800;
    font-size:12px;
    padding-top: 1px;
}

.sectionsmallheader {
  @apply tw-text-sm #{!important};
}

.mat-select-panel {
    max-height: 350px !important;
}

body {
    font-family: $bodyfont;
    margin: 0;
    overflow-x: hidden;
    color: $bodytext;
    font-weight: 400;
    background: $white;
}

html {
    position: relative;
    min-height: 100%;
    background: $white;
}

.reflectionform {
    width:100%;
}

/*******************
Mat card
*******************/
.mat-card{
  @apply tw-mx-2 tw-mb-4 tw-mt-2;
  padding: 0px!important;

  .mat-card-content{
    @apply tw-text-base tw-p-6;
    @media screen and (max-width: 800px) {
        font-size: 0.8rem;
    }
  }

  .mat-card-image {
    width: calc(100% + 0px);
    margin: 0 0px 0px 0px;
  }

  .mat-card-header {
        padding: 0;
        padding-bottom:0;
        border-radius: $radius $radius 0 0;
        .mat-card-title{
            margin-bottom: 2px;
            font-size: 20px;
            @media screen and (max-width: 800px) {
            font-size: 15px;
            }
        }
        .mat-card-subtitle, .mat-card-subtitle:not(:first-child){
            margin-bottom: 0px;
            margin-top: 0px;
            font-size: 14px;
        }
        &.text-white{
        .mat-card-title{
            color:$white;
            }
            .mat-card-subtitle{
                color:rgba(255, 255, 255, 0.7);
            }
        }
    }

    .mat-card-title{
        font-size: 20px;
        margin-bottom: 5px;
    }
    .mat-card-subtitle{
        margin-bottom: 20px;
    }
    .mat-card-actions{
        margin: 0px!important;
    width: calc(100% + 0px);
    margin: 0 0px 0px 0px;
    }



}

.modal-content {
    border:0;
}

.closeicon {
    font-size:30px;
    padding-right:30px;
    color: #fff;
    cursor:pointer;
    margin-top: -5px;
}

.nopaddingdialog .mat-dialog-container {
    padding:0;
}